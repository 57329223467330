import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiurl } from 'src/app/global';


@Injectable({
	providedIn: 'root'
})
export class AddItemService {

	constructor(private http: HttpClient) { }
	public itemEditEmitter:EventEmitter<any> = new EventEmitter();
	addItem(result: any,id:number){
		console.log(result);
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(apiurl + 'shopadmin/item/'+id+'/',result,{headers:headers});
	}
	getmaincategory(){
	
	  
		return this.http.get(apiurl + 'superadmin/maincategory/');
	}
	getcolor(){
		return this.http.get(apiurl + 'shopadmin/color/');
	}
	getfuel(id:number){
		return this.http.get(apiurl + 'shopadmin/fuel/'+id+"/");
	}
	getitems(id:number){
		return this.http.get(apiurl + 'shopadmin/getallitem/'+id+"/");
	}
	getfavitems(id:number){
		return this.http.get(apiurl + 'shopadmin/favlist/'+id+"/");
	}
	getcount(){
		return this.http.get(apiurl + 'shopadmin/count/');
	}
	getOneItem(cid:number){
		return this.http.get(apiurl+"shopadmin/getsingleitem/"+cid+"/")
	}
	getcategory(id:number){
		return this.http.get(apiurl + 'superadmin/category/'+id+"/");
	}
	getsubcategory(id:number){
		return this.http.get(apiurl + 'superadmin/subcategory/' +id+"/");
	}
	delItem(id:number){
		return this.http.get(apiurl + 'shopadmin/itemdelete/' +id+"/");
	}
	delitemimage(id:number){
		return this.http.get(apiurl + 'shopadmin/imdelete/' +id+"/");
	}
	edititem(result: any,id:number){
		console.log(result);
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.put(apiurl + 'shopadmin/itemedit/'+id+'/',result,{headers:headers});

	}
	getcontacts(id:number){
		return this.http.get(apiurl + 'shopadmin/testdrive/'+id+'/');
	}

	getitemtypes(){
		return this.http.get(apiurl + 'shopadmin/itemtypes/');
	}
	getitemAttributes(itemtypeid:number){
		return this.http.get(apiurl + 'shopadmin/itemattr/'+itemtypeid+'/');
	}
	getitemattributeval(itemtypeid:number){
		return this.http.get(apiurl + 'shopadmin/itemattrval/'+itemtypeid+'/');
	}
	getcategories(shopid:number){
		return this.http.get(apiurl + 'shopadmin/getcategories/'+shopid+"/");
	}
	getusershopdetails(userid:number){
		return this.http.get(apiurl + 'shopadmin/getusershopdetails/'+userid+"/");
	}
	getitemAttributesChoices(itemattrid:number){
		return this.http.get(apiurl + 'shopadmin/itemattrchoices/'+itemattrid+'/');
	}

	saveitemtodb(data:any){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(apiurl + 'shopadmin/saveitemtodb/',data,{headers:headers});
	}

	getbrands(cid:number){
		return this.http.get(apiurl + 'shopadmin/getbrands/'+cid+'/');
	}
	getvehiclemodels(bid:number){
		return this.http.get(apiurl + 'shopadmin/getvehiclemodels/'+bid+'/');

	}


}
