import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiurl } from 'src/app/global';


@Injectable({
  providedIn: 'root'
})
export class ListingService {
  
  constructor(private http: HttpClient) { }
  
  public listEditEmitter:EventEmitter<any> = new EventEmitter();
  
  searchlist(result: any){
    //console.log(result.location);
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');   
        return this.http.get(apiurl + 'shopadmin/item/'+ '?' + 'tag'+ '=' +result.search + '&'+'maincategory'+ '=' +result.maincategoryid+ '&'+ 'category'+ '=' +result.categoryid+ '&'+ 'subcategory'+ '=' +result.subcategoryid + '&'+ 'districtid'+ '=' +result.location+ '&'+ 'price'+ '=' +result.pricefrom+ '&'+ 'price'+ '=' +result.priceto+ '&'+ 'year'+ '=' +result.yearfrom+ '&'+ 'year'+ '=' +result.yearto+ '&'+ 'kilometer'+ '=' +result.kmfrom+ '&'+ 'kilometer'+ '=' +result.kmto+ '&'+ 'fuel'+ '=' +result.fuel+ '&'+ 'color'+ '=' +result.colorid);  

      }
      editfavorite(data:any,cid:number){
        //console.log(data);
        const headers = new HttpHeaders();
        headers.append('Content-Type','application/json');
        return this.http.put(apiurl+"shopadmin/updatefav/"+cid+"/",data,{headers:headers})
        }
        addcount(data:any,cid:number){
          //console.log(data);
          const headers = new HttpHeaders();
          headers.append('Content-Type','application/json');
          return this.http.put(apiurl+"shopadmin/updatecount/"+cid+"/",data,{headers:headers})
          }
    getcolor(){
      return this.http.get(apiurl + 'shopadmin/color/');
    }
    getfuel(id:number){
      return this.http.get(apiurl + 'shopadmin/fuel/'+id+"/");
    }    getitemtypes(){
      return this.http.get(apiurl + 'shopadmin/itemtypes/');
    }
    getbrands(cid:number){
      return this.http.get(apiurl + 'shopadmin/getbrands/'+cid+'/');
    }
    getvehiclemodels(bid:number){
      return this.http.get(apiurl + 'shopadmin/getvehiclemodels/'+bid+'/');
  
    }
    getcount(){
      return this.http.get(apiurl + 'shopadmin/count/');
    }
    getlocation(){
          return this.http.get(apiurl + 'superadmin/towns/');             
          }
    
    getitems(){
          return this.http.get(apiurl + 'user/getcompleteitems/');             
         }
    getOneItem(cid:number){
          return this.http.get(apiurl+"user/getsingleitem/"+cid+"/")
        }
        getNews(cid:any){
          const headers = new HttpHeaders();
          headers.append('Content-Type','application/json');   
          return this.http.post(apiurl + 'superadmin/checknewsbyemail/',cid,{headers:headers});
        }
    addtestdrive(result: any,id:number){
          console.log(result);
          const headers = new HttpHeaders();
          headers.append('Content-Type','application/json');   
          return this.http.post(apiurl + 'shopadmin/testdrivecreate/'+id+'/',result,{headers:headers});
        
            }
            addnewsletter(result: any){
              console.log(result);
              const headers = new HttpHeaders();
              headers.append('Content-Type','application/json');   
              return this.http.post(apiurl + 'superadmin/newsletter/',result,{headers:headers});
            
                }
    search1(result: any){
              console.log(result);
              const headers = new HttpHeaders();
              headers.append('Content-Type','application/json');   
             //return this.http.post(apiurl + 'user/search1/',{params: result,headers:headers});
            return this.http.post(apiurl + 'user/search1/',result,{headers:headers});
            
                }
   search2(result: any){
                  console.log(result);
                  const headers = new HttpHeaders();
                  headers.append('Content-Type','application/json');   
                 //return this.http.post(apiurl + 'user/search1/',{params: result,headers:headers});
                return this.http.post(apiurl + 'user/search2/',result,{headers:headers});
                
                    }    
  login_social(result: any){
                      console.log(result);
                      const headers = new HttpHeaders();
                      headers.append('Content-Type','application/json');   
                     //return this.http.post(apiurl + 'user/search1/',{params: result,headers:headers});
                    return this.http.post(apiurl + 'socialregauth/',result,{headers:headers});
                    
                   }                         
}